

/* CSS from section stylesheet tags */
.revy-custom-content-section-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px;
    max-width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}